import React from "react"
import { useLocalization } from "gatsby-theme-i18n"
import Fuse from "fuse.js"
import { Spacer, Section, Avatar, Image, DropButtons, Checkbox } from "@bw/bits"
import { CollectionTeaser } from "@bw/partials"
import { Search } from "@bw/icons"
import { Layout } from "@bw/layouts"
import styled from "styled-components"
import { graphql } from "gatsby"
import { mediaQuery } from "../theme"
import { Input } from "@bw/forms"
import { Formik, Form, Field } from "formik"
import { useTranslation } from "react-i18next"

const initialValues = {
  search: "",
  is_nsfw: false,
  tags: [],
}

const ExploreCollections = ({ data, location, pageContext }) => {
  const { tag: presetTag } =
    typeof window === "undefined" ? {} : location?.state
  const { locale, localizedPath, defaultLang } = useLocalization()
  const [filter, setFilter] = React.useState(initialValues)
  const allCollections = data.allCollection.nodes
  const siteSearchIndex = data.siteSearchIndex
  const { t } = useTranslation()

  const fuse = React.useMemo(() => {
    const index = Fuse.parseIndex(siteSearchIndex.fuse.index)

    return new Fuse(
      siteSearchIndex.fuse.documents,
      {
        keys: [
          {
            name: "name",
            weight: 3,
          },
          {
            name: "description",
            weight: 4,
          },
          {
            name: "symbol",
            weight: 4,
          },
        ],
        minMatchCharLength: 3,
        ignoreLocation: true,
        threshold: 0.4,
      },
      index
    )
  }, [siteSearchIndex])

  let timer
  React.useEffect(() => {
    if (presetTag) {
      setFilter(filter => ({
        ...filter,
        tags: [presetTag],
      }))
    }
  }, [presetTag])

  const tags = React.useMemo(() => {
    const { tags } = allCollections.reduce(
      (acc, collection) => {
        if (!collection.tags) {
          return acc
        }

        for (let i = 0; i < collection.tags.length; i++) {
          if (!acc.keys.includes(collection.tags[i].slug)) {
            const { slug, title } = collection.tags[i]
            acc.keys.push(slug)
            acc.tags.push({
              value: slug,
              name: title,
              active: presetTag === slug,
            })
          }
        }

        return acc
      },
      { keys: [], tags: [] }
    )

    tags.sort((a, b) => a.name.localeCompare(b.name))

    return tags
  }, [allCollections, presetTag])

  const results = React.useMemo(() => {
    let collections = [...allCollections]

    if (filter.search.length > 2) {
      collections = fuse.search(filter.search).map(i => i.item)
    }

    if (filter.is_nsfw === false) {
      collections = collections.filter(collection => !collection.is_nsfw)
    }

    if (filter.tags.length > 0) {
      collections = collections.filter(collection => {
        const collectionTags = collection.tags.map(t => t.slug)
        for (let i = 0; i < collectionTags.length; i++) {
          if (filter.tags.includes(collectionTags[i])) {
            return true
          }
        }

        return false
      })
    }

    return collections
  }, [filter, allCollections, fuse])

  const isShowingAllCollections = allCollections.length === results.length

  return (
    <Layout {...{ pageContext }}>
      <Section
        containerSize="large"
        title={t("collection.explore.title")}
        subtitle={
          isShowingAllCollections
            ? t("collection.explore.showingAllXCollections", {
                total: allCollections.length,
              })
            : t("collection.explore.showingXofYCollections", {
                found: results.length,
                total: allCollections.length,
              })
        }
      />
      <Section containerSize="large">
        <Formik
          initialValues={initialValues}
          onSubmit={values => {
            setFilter(values)
          }}
        >
          {({ submitForm }) => (
            <Form
              onChange={() => {
                clearTimeout(timer)
                timer = setTimeout(submitForm, 500)
              }}
            >
              <Spacer direction="row" align="center">
                <div style={{ width: "320px" }}>
                  <Field
                    name="search"
                    type="text"
                    component={Input}
                    icon={<Search />}
                    placeholder={t("search")}
                  />
                </div>
                <DropButtons
                  label={t("category")}
                  onChange={list => {
                    setFilter({
                      ...filter,
                      tags: list.filter(t => t.active).map(t => t.value),
                    })
                  }}
                  categories={tags}
                />
                <Field
                  name="is_nsfw"
                  label={t("collection.explore.is_nsfw")}
                  component={Checkbox}
                />
              </Spacer>
            </Form>
          )}
        </Formik>
      </Section>
      <Section containerSize="large">
        {results.length === 0 && filter !== initialValues ? (
          <div>{t("noResults")}</div>
        ) : (
          <Grid>
            {results.map(collection => (
              <CollectionTeaser
                key={collection.id}
                to={localizedPath({
                  defaultLang,
                  locale,
                  path: `/collection/${collection.symbol}`,
                })}
                name={collection.name}
                likes={collection.likes_count}
                image={
                  <Image
                    image={collection.square_teaser_url}
                    type="NFT"
                    avatar={<Avatar image={collection.icon_url} />}
                  />
                }
              />
            ))}
          </Grid>
        )}
      </Section>
    </Layout>
  )
}

export default ExploreCollections

export const exploreCollectionsQuery = graphql`
  query collectionsQuery {
    allCollection(sort: { fields: statamicId, order: DESC }) {
      nodes {
        id
        name
        symbol
        is_nsfw
        square_teaser_url
        icon_url
        likes_count
        tags {
          slug
          title
        }
      }
    }
    siteSearchIndex {
      fuse
    }
  }
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 25px;

  ${mediaQuery("small")`
  grid-gap: 45px;
  `};

  ${mediaQuery("medium")`
    grid-template-columns: 1fr 1fr 1fr;
  `};

  ${mediaQuery("large")`
    grid-template-columns: 1fr 1fr 1fr 1fr;
  `};
`
